import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Image, SpinnrLoader } from '../components/Components';
import { Box } from '../components/Style'
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { galleryDeleteApi, getGallery, uploadGalleryAPi } from '../constant/api/Api';
import { IMAGE_URL } from '../constant/Keys';
const Gallery = () => {
    const { langauge } = useSelector((state)=>state.clients);
    const [media,setMedia] = useState([]);
    const [getMedia,setGetMedia] = useState([]);
    const [loader,setLoader] = useState(false);
    const [pageLoader,setPageLoader] = useState(false);
    const setMediaFormate = async (images) => {
        let array_of_images = [];
        const allowedFileTypes = ["jpg", "JPG", "PNG", "WEBP", "png", "jpeg", "webp"];
        await Array.from(images).forEach(file => { 
            var file_size = file.size;
            var file_extension = file.name.split('.').pop();
            if (allowedFileTypes.includes(file_extension)) {
                if(file_size > 10240000){
                    toast.error("Allow only 10MB");
                }else{
                    array_of_images.push(file) 
                    console.log(array_of_images);
                    setMedia(media.concat(array_of_images))
                }
            }else{
                toast.error("accept only images");
            }
        })
    }
    useEffect(async() => {
        setPageLoader(true)
        try{
            const res = await getGallery();
            if(res.data.success){
                setGetMedia(res.data.data)
            }
        }
        catch(error){

        }
        setPageLoader(false)
    },[])
    const UploadGallery = async () => {
        if(!media.length){
            toast.error('Please upload minimum 1 picture');
            return;
        }
        setLoader(true);
        const formData = new FormData();
        if(media.length>0){
            media.map((item,i)=>{
                formData.append(`media[${i}]`,item);
            })
        }
        try{       
            const res = await uploadGalleryAPi(formData);
            if(res.data.success){
                toast.success(res.data.message);
                setMedia([]);
                setGetMedia(res.data.data.gallery)
            }else{
                toast.error(res.data.message);
            }
        }
        catch(error){
            toast.error('Something went wrong');
        }
        setLoader(false);
    }
    const deleteGalleryImage = async (id) => {
        setLoader(true)
        try{
            const res = await galleryDeleteApi({gallery_id:id})
            if(res.data.success){
                setGetMedia(getMedia.filter((x)=>x.id!==id))
            }else{
                toast.error(res.data.message);
            }
        }
        catch(error){

        }
        setLoader(false)
    }
  return (
    <main>
        <Box className="container px-0">
            <MainGalleryWrapper>
                <Box className="card card__responsive" style={{overflow: "visible"}}>
                    {!pageLoader?
                        <Box>
                            <Box className="card-header res_head d-flex justify-content-between border-md-bottom d-md-flex">
                                <h1 className="m-0 text-black align-items-center text-capitalize align-self-center" style={{fontSize:"27px",lineHeight:1}}>Gallery</h1>
                                <button className="btn btn-priamry px-5" disabled={loader} onClick={()=>UploadGallery()} style={{width:"fit-content",minWidth:"inherit"}}>
                                    {loader?<SpinnrLoader />:"Save"}
                                </button>
                            </Box>
                            <Box className="card-body card__body py-md-4">
                                <Box className="user-listing d-flex pt-0">
                                    <Box className='user-card bg-transparent'>
                                        <label className="file-upload-gallery w-100 d-flex align-items-center justify-content-center">
                                            <i className="ri-add-circle-fill position-static"></i>
                                            <input multiple accept="image/*" onChange={(e) => setMediaFormate(e.target.files)} className="d-none" type="file" />
                                        </label>
                                    </Box>
                                    {getMedia.length>0 && getMedia.map((item,key)=>(
                                        <Box className={`user-card position-relative`} key={`item__${key}`}>
                                            <Image
                                                className="w-100"
                                                src={IMAGE_URL+item.image}
                                                style={{
                                                    height: "100%",
                                                    maxHeight: "initial",
                                                    objectFit:"cover"
                                                }}
                                            />
                                            {!loader?
                                                <i className="ri-close-circle-fill position-absolute" style={{color: "Gray"}} 
                                                    onClick={()=>deleteGalleryImage(item.id)} type="button">
                                                </i>
                                            :null}
                                        </Box>
                                    ))}
                                    {media.length>0 && media.map((item,key)=>(
                                        <Box className={`user-card position-relative`} key={`item__${key}`}>
                                            <Image
                                                className="w-100"
                                                src={URL.createObjectURL(item)}
                                                style={{
                                                    height: "100%",
                                                    maxHeight: "initial",
                                                    objectFit:"cover"
                                                }}
                                            />
                                            {!loader?
                                                <i className="ri-close-circle-fill position-absolute" style={{color:"Gray"}} 
                                                    onClick={()=>setMedia(media.filter((x,i)=>i!==key))} type="button">
                                                </i>
                                            :null}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    :<Box className="p-5 text-center"><SpinnrLoader /></Box>}
                </Box>
            </MainGalleryWrapper>
        </Box>
    </main>
  )
}
const MainGalleryWrapper = styled.div`
  .card-header.res_head {
    @media(max-width: 575px){
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
  }
  .user-card{
        overflow:hidden;
        .ri-close-circle-fill{
            font-size:30px;
            top:0;
            z-index:22;
            right:6px;
        }
        &.bg-transparent{
            border:3px dashed #D39B2C;
            &:before{
                display:none;
            }
        }
        .lazy-load-image-background{
            width:100%;
            height:200px;
            img{
                object-position:top center;
            }
        }
    }
    .file-upload-gallery{
        cursor:pointer;
        height:200px;
        i{
            font-size:50px;
            color:#D39B2C;
        }
        @media(max-width: 575px){
            height: 100%;
        }
    }
`
export default Gallery