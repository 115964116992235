import React, { useState } from "react";
import Styles from "./Styles1";
import { Form, Field } from "react-final-form";
import Card from "./Card";
import { useSelector } from "react-redux";
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "./CardUtils";
import { PaymentApi } from "../../constant/api/Api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOAD_USER_SUCCESS } from "../../redux/constants/userConstant";
import { Box } from "../Style";
import HebrewLanguage from "../../constant/langauges/HebrewLanguage";
import EnglishLanguage from "../../constant/langauges/EnglishLanguage";

import {useWindowSize} from '../../helpers/helper';


const PaymentCard = ({ amount,setPaymentFormShow }) => {
  const {width} = useWindowSize()

  const dispatch = useDispatch();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { langauge } = useSelector((state)=>state.clients);
  const lang = langauge==="heb"?HebrewLanguage:EnglishLanguage;
  const user = useSelector((state) => state.auth.user);

  // const[cardNumber, setCardNumber] = useState(lang.card_number)
 
  const onSubmit = async (values) => {
    console.log(values);
    await sleep(0);
    var parts = values.expiry.split("/");
    const formData = new FormData();
    formData.append("card_number", values.number);
    formData.append("cvc", values.cvc);
    formData.append("name", values.name);
    formData.append("id", values.id);
    formData.append("year", parts[parts.length - 1]);
    formData.append("month", parts[parts.length - 2]);
    formData.append("amount", amount);
    formData.append("saved_details", values.saved_details?1:0);
    console.log("Form data is :", formData) // delete
    
    try {
      const res = await PaymentApi(formData);
      if (res.data.status) {
        dispatch({
          type: LOAD_USER_SUCCESS,
          payload: res.data.data,
        });
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("משהו השתבש");
    }
  };


  // const handleOnChangeNumber = (e) => {
  //   var c_num = e.target.value
  //   var clength = c_num.length

  //   var final_c_num = c_num.slice(0, 4)+" "+c_num.slice(4, 8)+" "+c_num.slice(8, 12)+" "+c_num.slice(12, clength);
  //   setCardNumber(final_c_num)
  //   console.log(" ========> ", final_c_num)
  // }
  

  return (
    <Styles>
      <Form onSubmit={onSubmit} render={({handleSubmit, form, submitting, pristine, values, active,}) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box className="text-end">
                <button type="button" onClick={()=>setPaymentFormShow(false)} className="border-0 bg-transparent ms-auto btn-modal-closer-black-and-white">
                  <i className="ri-close-circle-fill"></i>
                </button>
              </Box>
              <Card
                number={values.number || ""}
                name={values.name || ""}
                expiry={values.expiry || ""}
                cvc={values.cvc || ""}
                focused={active}
              />
              <div className="mt-3">
                <Field
                  name="number"
                  component="input"
                  className="ms-0"
                  type="text"
                  pattern="[\d| ]{16,22}"
                  placeholder={lang.card_number}
                  format={formatCreditCardNumber}
                  required={true}
                  dir='ltr'
                  style={langauge==="heb"?{textAlign:'right'}:{textAlign:'left'}}

                />
              </div>
              <div>
                <Field
                  name="name"
                  component="input"
                  className="ms-0"
                  type="text"
                  placeholder={lang.name}
                  required={true}
                />
              </div>
              <div>
                <Field
                  name="id"
                  component="input"
                  className="ms-0"
                  type="text"
                  placeholder={lang.id}
                  required={true}
                />
              </div>

              {width>500 ? (
                <div>
                  <Field
                    name="expiry"
                    component="input"
                    type="text"
                    maxLength="7"
                    className="ms-0"
                    placeholder={lang.valid_date}
                    format={formatExpirationDate}
                    required={true}
                  />
                  <Field
                    name="cvc"
                    component="input"
                    type="text"
                    pattern="\d{3,4}"
                    placeholder={lang.cvc}
                    format={formatCVC}
                    required={true}
                    style={{marginRight:langauge==="heb"?"15px":"0px",marginLeft:langauge!=="heb"?"15px":"0px"}}
                  />
                </div>
              ):(<>
                  <div>
                    <Field
                      name="expiry"
                      component="input"
                      type="text"
                      maxLength="7"
                      className="ms-0"
                      placeholder={lang.valid_date}
                      format={formatExpirationDate}
                      required={true}
                    />
                  </div>
                  <div>
                    <Field
                      name="cvc"
                      component="input"
                      type="text"
                      pattern="\d{3,4}"
                      placeholder={lang.cvc}
                      format={formatCVC}
                      required={true}
                      className="ms-0"
                      style={{marginRight:langauge==="heb"?"0px":"0px",marginLeft:langauge!=="heb"?"0px":"0px"}}
                    />
                  </div>
                </>)}
              
              <div className="buttons">
                <button type="submit" disabled={submitting}>
                  {user.free_trial==0?lang.submit:lang.submitPayment}
                </button>
                {/* <button type="button" onClick={form.reset} disabled={submitting || pristine}>{lang.reset}</button> */}
              </div>

              <div> 
                <p style={{width:"100%",fontSize:"14px", lineHeight:"normal", textAlign:'center'}}>
                  {lang.save_card}
                  {/* <Field component="input" type="checkbox" name="saved_details" className={`cheee__ mt-1 ${langauge==="heb"?"ms-2":"me-2"}`} style={{width:"17px",height:"17px"}} /> */}
                </p>
              </div>
              
            </form>
          );}}/>
    </Styles>
  );
};

export default PaymentCard;