import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import styled from "styled-components";
import { Box, Input, Label } from "./Style";
import Dropdown from "react-bootstrap/Dropdown";
import { EnglishLangaugeFlag, HebrewLangaugeFlag, locationIcon, Logo } from "./AllImages";
import { useDispatch, useSelector } from "react-redux";
import { SETLANGUAGE } from "../constant/Keys";
import Select from "react-select";
import { genderOptions } from "../helpers/helper";
const SpinnrLoader = (color) => {
  return (
    <Spinner
      animation="border"
      style={{ color: color ? color : "#1a75bc" }}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

const Image = (props) => {
  return <LazyLoadImage effect="blur" {...props} />;
};

const LogoSite = (props) => {
  return <LazyLoadImage effect="blur" src={Logo} {...props} />;
};

const LanguageDropdownSelector = ({ lang, style={} }) => {
  const {user} = useSelector((state)=>state.auth);
  const { langauge } = useSelector((state) => state.clients);
  if(typeof user === 'object' && user !== null)
  {
    if(Object.keys(user).length == 0){
      var dropdown = langauge === 'eng' ? {} : {textAlign: "end"};
    }else{
      var dropdown = langauge === 'eng' ? {} : {textAlign: "initial"};
    }
  }else{
    var dropdown = langauge === 'eng' ? {} : {textAlign: "end"};
  }
  const dispatch = useDispatch();
  return (
    <LanguageDropdown style={style}>
      <Dropdown>
        <Dropdown.Toggle
          className="border-0 p-0"
          style={{ background: "none", minWidth: 0, boxShadow: "none" }}
        >
          <Image
            src={langauge !== "heb" ? EnglishLangaugeFlag : HebrewLangaugeFlag}
            width="27px"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            style={dropdown}
            className="text-capitalize"
            onClick={() => dispatch({ type: SETLANGUAGE, payload: "eng" })}
          >
            <Image
              src={EnglishLangaugeFlag}
              width="25px"
              className="border shadow-sm"
            />{" "}
            {lang.english}
          </Dropdown.Item>
          <Dropdown.Item
            style={dropdown}
            className="text-capitalize"
            onClick={() => dispatch({ type: SETLANGUAGE, payload: "heb" })}
          >
            <Image
              src={HebrewLangaugeFlag}
              width="25px"
              className="border shadow-sm"
            />{" "}
            {lang.hebrew}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </LanguageDropdown>
  );
};



const ForgetPasswordModel = ({
  onChangeEmail,
  forgetSubmitEvent,
  show,
  setVisibility,
  lang,
}) => {
  return (
    <ForgetModelContainer>
      <section
        className="signup-wrapper h-100 d-flex flex-column justify-content-center"
        style={{ background: "none" }}
      >
        <div className="container">
          <div className="signup-widget forget_widget mx-auto col-lg-4 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body pb-5">
                <div className="logo color mb-2">
                  <LogoSite height="50px" />
                </div>
                <div className="signup-content">
                  {!show.success ? (
                    <>
                      <div className="text-center py-2 text-capitalize">
                        <h3 style={{ fontWeight: 700 }}>
                          {lang.password_reset}
                        </h3>
                      </div>
                      <div className="input">
                        <input
                          required={true}
                          onChange={(e) => onChangeEmail(e.target.value)}
                          type="email"
                          placeholder={lang.email}
                        />
                        <i className="ri-mail-unread-line"></i>
                      </div>
                      <button
                        disabled={show.loading}
                        d
                        className="btn"
                        onClick={forgetSubmitEvent}
                      >
                        {lang.reset_password}
                      </button>
                    </>
                  ) : (
                    <div className="text-center">
                      <h3 className="pt-3" style={{ fontWeight: 700 }}>
                        {lang.success_reset}
                      </h3>
                      <p>{lang.email_reset_alert}</p>
                    </div>
                  )}
                  <button
                    className="btn pink mt-3"
                    disabled={show.loading}
                    onClick={() =>
                      setVisibility({ ...show, show: false, success: false })
                    }
                  >
                    {show.success ? lang.login : lang.cancel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ForgetModelContainer>
  );
};


const TableSearchBar = ({ onChange, value }) => {
  return (
    <Box className="position-relative w-100">
      <Input
        className="w-100 mb-0"
        placeholder="Search by email"
        type="text"
        style={{ paddingLeft: "49px" }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <i className="position-absolute" style={{ left: "15px", top: "9px" }}>
        <i
          className="ri-user-search-line"
          style={{ fontSize: "18px", color: "#828282" }}
        ></i>
      </i>
    </Box>
  );
};


const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const [active, setActive] = useState(1);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const setPaginate = (number) => {
    paginate(number);
    setActive(number);
  };
  return (
    <nav className="mt-5">
      <ul className="pagination ms-auto w-fit-content">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${active == number ? "active" : ""}`}
          >
            <a onClick={() => setPaginate(number)} className={`page-link cursor-pointer text-decoration-none`}>
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};


const MobileScreenFilter = ({lang,filter_search,setFilterSearch,cityOptions,max_age_options,age_options}) => {
  return (
    <>
      <div className="input mb-4">
        <select 
          value={filter_search.city}
          className="w-100"
          onChange={(e) => setFilterSearch({ ...filter_search, city: e.target.value })}
          style={{backgroundImage:`url(${locationIcon})`,backgroundSize:"16px"}}
          >
          <option hidden selected value="">{lang.location}</option>
          {cityOptions.map((item,key)=>(
            <option key={key} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
      <h4>{lang.age_range}</h4>
      <div className="row">
        <div className="col-6">
          <div className="input">
            <select 
              className="w-100"
              onChange={(e) => setFilterSearch({ ...filter_search, birth_date: e.target.value })}
              value={filter_search.birth_date}
              >
              <option hidden selected value="" className="text-capitalize">{lang.min_age.replace(/\b\w/g, l => l.toUpperCase())}</option>
              {age_options.map((item,key)=>(
                <option key={key} value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-6">
          <div className="input">
            <select 
              className="w-100"
              onChange={(e) => setFilterSearch({ ...filter_search, birth_max_date: e.target.value })}
              value={filter_search.birth_max_date}
              >
              <option hidden selected value="" className="text-capitalize">{lang.max_age.replace(/\b\w/g, l => l.toUpperCase())}</option>
              {max_age_options.map((item,key)=>(
                <option key={key} value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {/* <h4 className="text-capitalize">{lang.gender}</h4> */}
      {/* <div className="row">
        {genderOptions.map((item,key)=>(
          <div className="col-4" key={key}>
            <div className="input radio">
              <input type="radio" id={`${item.value}${key}`} name={'gender'} onChange={()=>setFilterSearch({ ...filter_search, looking_for: item.value })} />
              <label for={`${item.value}${key}`}>{item.label}</label>
            </div>
          </div>
        ))}
        <div className="col-4">
          <div className="input radio">
            <input type="radio" id={`both__`} name={'gender'} onChange={()=>setFilterSearch({ ...filter_search, looking_for: "both" })} />
            <label for={`both__`}>{lang.both}</label>
          </div>
        </div>
      </div> */}
      <div className="input mt-3">
        <input type="text" placeholder={lang.search_name} onChange={(e) =>
            setFilterSearch({
              ...filter_search,
              search: e.target.value,
            })
          }     
          value={filter_search.search} />
        <i className="ri-user-line"></i>
      </div>
    </>
  )
}


const LargeScreenFilter = ({lang,filter_search,setFilterSearch,cityOptions,max_age_options,age_options}) => {
  return (
    <>
      <Box className="mb-3">
        <Label className="mb-2 text-capitalize">{lang.gender}</Label>
        <Select
          options={genderOptions}
          value={
            filter_search.looking_for
              ? genderOptions.find(
                  (c) => c.value === filter_search.looking_for
                )
              : { label: lang.select, value: "" }
          }
          onChange={(e) =>
            setFilterSearch({ ...filter_search, looking_for: e.value })
          }
        />
      </Box>
      <Box className="mb-3">
        <Label className="mb-2 text-capitalize">{lang.min_age}</Label>
        <Select
          options={age_options}
          value={
            filter_search.birth_date
              ? age_options.find((c) => c.value === filter_search.birth_date)
              : { label: lang.select, value: "" }
          }
          onChange={(e) =>
            setFilterSearch({ ...filter_search, birth_date: e.value })
          }
        />
      </Box>
      <Box className="mb-3">
        <Label className="mb-2 text-capitalize">{lang.max_age}</Label>
        <Select
          options={max_age_options}
          value={
            filter_search.birth_max_date
              ? max_age_options.find(
                  (c) => c.value === filter_search.birth_max_date
                )
              : { label: lang.select, value: "" }
          }
          onChange={(e) =>
            setFilterSearch({
              ...filter_search,
              birth_max_date: e.value,
            })
          }
        />
      </Box>
      <Box className="mb-3">
        <Label className="mb-2">{lang.location}</Label>
        {filter_search.city}
        <Select
          options={cityOptions}
          value={
            filter_search.city
              ? cityOptions.find((c) => c.value === filter_search.city)
              : { label: lang.select, value: "" }
          }
          onChange={(e) => setFilterSearch({ ...filter_search, city: e.value })}
        />
      </Box>
      <Box className="position-relative flex-1 d-md-none mb-3">
        <Label className="mb-2">{lang.search_name}</Label>
        <Input
          type="text"
          placeholder={lang.search_name}
          value={filter_search.search}
          style={{
            paddingRight: "40px",
            height: "36px",
            paddingLeft: "8px",
            background: "#fff",
            color: "#333333",
          }}
          onChange={(e) =>
            setFilterSearch({
              ...filter_search,
              search: e.target.value,
            })
          }
        />
        <i
          className="ri-map-pin-line"
          style={{
            fontSize: "18px",
            position: "absolute",
            top: "34px",
            right: "10px",
          }}
        ></i>
      </Box>
    </>
  );
};


export const ForgetModelContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .card {
    box-shadow: none !important;
  }
`;


const LanguageDropdown = styled.div`
  a {
    font-size: 13px;
    font-weight: 600;
    color: #828282 !important;
    margin-bottom: 2px;
    span {
      margin-right: 5px;
    }
  }
`;

export {
  SpinnrLoader,
  Image,
  ForgetPasswordModel,
  TableSearchBar,
  Pagination,
  LanguageDropdownSelector,
  LogoSite,
  LargeScreenFilter,
  MobileScreenFilter
};
