import React, { useEffect, useState } from 'react'
import { Row,Col,Form } from 'react-bootstrap';
import { GetUsers } from './Discover';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, set } from 'react-hook-form';
import { useParams } from 'react-router';
import { Image, SpinnrLoader } from '../components/Components';
import { Box, Heading } from '../components/Style';
import { abstract, cheers, FemalePicture, globe, Homepage, MalePicture, man, balance, eye, education, body } from '../components/AllImages' // user1
import { Link , useHistory } from 'react-router-dom'
import { getAge } from '../helpers/helper'; // getDate
import {ASSET_URL, SETLIKES, IMAGE_URL} from '../constant/Keys';
import { connectUser, PostLikeUser, RemoveLike, getSingleUser } from '../constant/api/Api';
import { toast } from 'react-toastify';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { UpgradePremiumModel } from '../components/Ui/Popups';
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const Profile = ({lang,admin}) => {
    const {user} = useSelector((state)=>state.auth);
    const [client,setClient] = useState({loader:true,data:null});
    const { langauge } = useSelector((state)=>state.clients);
    const [loaderConnect,setLoaderConnect] = useState(false);
    const [profile,setProfile] = useState([]);
    const [modalView, setModalView] = useState(false);
    const history = useHistory();
    const {id} = useParams();
    const [bio, setBio] = useState('')

    useEffect(() => {
        const getUsersData = async () => {   
            setClient({loader:true,data:null});  
            // const {data, message} = await GetUsers(dispatch);
            // const {findUser, message} = await getSingleUser(id);
            const apiResponse = await getSingleUser(id);
            const message = "";
            const findUser = apiResponse.data.data;

            if(findUser){
                // console.log("GET USER DATA :", id, findUser)  
                // const findUser = await data.find(function (element) {
                //     return element.id == id;
                // });
                try{
                    setClient({...client, data:findUser, loader:false});
                }catch(excep){
                    console.log(excep)
                }

                // console.log("client :", client, findUser['about'])
                if(findUser.about?.length > 0){
                    setBio(findUser['about'])
                }else{
                    setBio('')
                }

                let arrayProfile = [];
                if(findUser.profile.length>0){
                    findUser.profile.map((item) => {
                        if(item?.profile){
                            arrayProfile.push( {
                                original: IMAGE_URL+item.profile,
                                thumbnail: IMAGE_URL+item.profile,
                            })
                        }
                    })
                }else{
                    const gender = findUser.gender;
                    const avatar = gender === "male"?MalePicture:FemalePicture;
                    arrayProfile.push( {
                        original: avatar,
                        thumbnail: avatar,
                    })
                }

                // console.log("ARRAY profile :", arrayProfile, arrayProfile.length)
                if(arrayProfile.length > 0){
                    setProfile(arrayProfile);
                }else{
                    const gender = findUser.gender;
                    const avatar = gender === "male"?MalePicture:FemalePicture;
                    arrayProfile.push( {
                        original: avatar,
                        thumbnail: avatar,
                    })
                    setProfile(arrayProfile);
                }
            }else{
                toast.error("משהו השתבש");
            }
        }
        getUsersData()
    },[id])


    const { register, handleSubmit, control, watch, getValues, formState: { errors } } =  useForm({});
    const connectingUser = async () => {
        setLoaderConnect(true);
        try{
            const res = await connectUser({connection_with:id});
            if(res.data.status){
                toast.success('החיבור נוצר בהצלחה.');
                setLoaderConnect(false);
            }
            history.push(`/inbox?u=${id}`);
        }
        catch{
            toast.error('החיבור נכשל');
            setLoaderConnect(false);
        }
    }


    const postLike = async (e,id) => {
        e.preventDefault();
        try{
            const {data} = await PostLikeUser({user_id:id});
            if(data.success){
                setClient(data);
                toast.success(data.message);
            }else{
                toast.error(data.message);
            }
        }
        catch(error){
            toast.error('משהו השתבש');
        }
    }


    const RemoveLiked = async (e,id) => {
        e.preventDefault();
        try{
            const {data} = await RemoveLike({user_id:id});
            if(data.success){
                let new_record = Object.assign({},client);
                new_record.data.like = 0;
                setClient(new_record);
                toast.success(data.message);
            }else{
                toast.error('משהו השתבש');
            }
        }
        catch(error){
            toast.error('משהו השתבש');
        }
    }


    const liked = client?.data?.like;




    return (
        <main className={admin==="1"?"user-side-wrap mt-5":""}>
            <Box className="d-block d-md-none position-relative">
                <ImageGallery showPlayButton={false} originalHeight={250} items={profile} />
                <Link to="/home" className="text-decoration-none position-absolute" style={{zIndex: 1,top:"15px",left:"15px" }}> 
                    <svg width="40" height="40" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="26" cy="26" r="26" fill="white" fillOpacity="0.2"/>
                        <g clipPath="url(#clip0_26_312)">
                        <path d="M25.9999 24.2325L32.1874 18.045L33.9549 19.8125L27.7674 26L33.9549 32.1875L32.1874 33.955L25.9999 27.7675L19.8124 33.955L18.0449 32.1875L24.2324 26L18.0449 19.8125L19.8124 18.045L25.9999 24.2325Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_26_312">
                        <rect width="30" height="30" fill="white" transform="translate(11 11)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </Link>
            </Box>


            <div className="container">
                {client.loader?
                    <Box className="text-center"><SpinnrLoader color="#000" /></Box>
                    :client.data?
                    <>
                        {admin==="1"?<Box className='mb-4'><Link className="d-flex align-items-center" to={`/admin/users`} style={{fontSize:"18px"}}><i className="ri-arrow-left-line" style={{fontSize:"30px"}}></i>&nbsp;&nbsp;Back</Link></Box>:null}
                        <div className="card">
                            <div className="card-body card__res_padding pb-md-4">
                                <Row className='pt-md-2'>
                                    <Col md={4} className="d-md-block d-none">
                                        <div className="gallary py-0">
                                            <ImageGallery showPlayButton={false} originalHeight={250} items={profile} />
                                        </div>
                                    </Col>
                                    <Col md={8} className="profile-widget profile_widget_table px-0 px-md-3">
                                        <div className="card-header  pt-3 align-items-center d-md-flex d-none  justify-content-between">
                                            <div className="title">
                                                <h2>{client.data?.name}</h2>
                                                <Box className="d-flex">
                                                    <span className='text-capitalize' style={{color:"#9A9A9A",fontWeight:700,fontSize:"16px"}}>{lang.cities[client.data?.city]}</span>
                                                    <span className={`badge ${langauge==="eng"?"ms-3":"me-3"} px-2 d-flex align-items-center align-self-center ${client.data?.gender === "male" ?"male":"female"}`} style={{lineHeight:1.1}}>
                                                        <i
                                                        className={`ri-${
                                                            client.data?.gender === "male" ? "men" : "women"
                                                        }-line`}
                                                        ></i>
                                                        &nbsp;
                                                        {client.data?.birth_date
                                                        ? getAge(client.data?.birth_date)
                                                        : "-"}
                                                    </span>
                                                </Box>
                                            </div>
                                            {admin!=="1" && client?.data.id!==user?.id?
                                            <Box className={`d-md-flex d-none align-items-center ${langauge==="eng"?"ms-auto":"me-auto"}`}>
                                                <Box type="button" style={{marginBottom:"0.5rem"}} onClick={
                                                    (e)=>
                                                    !liked?postLike(e,client?.data.id)
                                                    :RemoveLiked(e,client?.data?.id)
                                                    } 
                                                    className="btn-cirlce-round me-4 link lg-circle-btn rounded-circle mr-rtl
                                                    d-flex align-items-center justify-content-center text-capitalize">
                                                    <i className={`ri-star-${liked?"fill":"line link"}`} style={{fontSize:"30px"}}></i>&nbsp;&nbsp;
                                                    {/* {lang.favourite} */}
                                                </Box>
                                                <button type="button" onClick={()=>connectingUser()}
                                                    disabled={loaderConnect} className="bg-transparent border-0">
                                                    <Link
                                                        to={`/inbox?u=${client?.data?.id}`}
                                                        className="link d-flex align-items-center" style={{marginBottom:".5rem"}}>
                                                        <i className="ri-message-2-fill" style={{fontSize:"30px"}}></i>&nbsp;&nbsp;
                                                        {/* {loaderConnect?"Connecting...":lang.send_message} */}
                                                    </Link>
                                                </button>
                                                {/* <button className={`bg-transparent border-0 ${langauge==="eng"?"ms-4":"me-4"}`} style={{marginBottom:".5rem"}} type="button">
                                                    <i className="ri-flag-2-line" style={{fontSize:"30px",color:"#BDBBC3"}}></i>
                                                </button> */}
                                            </Box>
                                            :null}
                                            {
                                                admin!=="1" && client?.data.id===user?.id?
                                                    <Box className="ms-4">
                                                        <Link to={`/profile/edit`}
                                                            className="link d-flex align-items-center text-secondary" style={{marginBottom:".5rem"}}>
                                                            <i className="ri-pencil-fill" style={{fontSize:"20px"}}></i>&nbsp;&nbsp;
                                                            {/* {lang.edit_profile} */}
                                                        </Link>
                                                    </Box>
                                                :null
                                            }
                                        </div>
                                        <div className="title mb-md-0 mb-4 d-md-none position-relative border-after">
                                            <div className="d-flex align-items-center">
                                                <Box>
                                                    <h2 style={{fontSize:"25px",marginBottom:"5px",fontWeight:900}}>{client.data?.name}</h2>
                                                    <Box className="d-flex">
                                                        <span className='text-capitalize' style={{color:"#9A9A9A",fontWeight:700,fontSize:"16px"}}>{lang.cities[client.data?.city]}</span>
                                                        <span className={`badge ${langauge==="eng"?"ms-3":"me-3"} px-2 d-flex align-items-center align-self-center ${client.data?.gender === "male" ?"male":"female"}`} style={{lineHeight:1.1}}>
                                                            <i
                                                            className={`ri-${
                                                                client.data?.gender === "male" ? "men" : "women"
                                                            }-line`}
                                                            ></i>
                                                            &nbsp;
                                                            {client.data?.birth_date
                                                            ? getAge(client.data?.birth_date)
                                                            : "-"}
                                                        </span>
                                                    </Box>
                                                </Box>
                                                {admin!=="1"?
                                                <Box className={`d-md-none d-flex align-items-center ${langauge==="eng"?"ms-auto":"me-auto"}`}>
                                                    {client?.data.id!==user?.id?
                                                    <>
                                                        <Box type="button"onClick={
                                                            (e)=>
                                                            !liked?postLike(e,client?.data.id)
                                                            :RemoveLiked(e,client?.data?.id)
                                                            } 
                                                            className="btn-cirlce-round me-4 link lg-circle-btn rounded-circle mr-rtl
                                                            d-flex align-items-center justify-content-center text-capitalize">
                                                            <i className={`ri-star-${liked?"fill":"line link"}`} style={{fontSize:"30px"}}></i>&nbsp;&nbsp;
                                                        </Box>
                                                        <button type="button" onClick={()=>connectingUser()}
                                                            disabled={loaderConnect} className="bg-transparent border-0">
                                                            <Link
                                                                to={`/inbox?u=${client?.data?.id}`}
                                                                className="link d-flex align-items-center">
                                                                <i className="ri-message-2-fill" style={{fontSize:"24px"}}></i>
                                                            </Link>
                                                        </button>
                                                    </>
                                                    :null}
                                                    {/* {
                                                    admin!=="1" && client?.data.id!==user?.id?
                                                        <button className={`bg-transparent border-0 ${langauge==="eng"?"ms-4":"me-4"}`} type="button">
                                                            <i className="ri-flag-2-line" style={{fontSize:"24px",color:"#BDBBC3"}}></i>
                                                        </button>
                                                    :null
                                                    } */}
                                                </Box>
                                                :null}
                                                {
                                                    admin!=="1" && client?.data.id===user?.id?
                                                    <Box className="ms-4">
                                                        <Link to={`/profile/edit`}
                                                            className="link d-flex align-items-center text-secondary">
                                                            <i className="ri-pencil-fill" style={{fontSize:"24px"}}></i>&nbsp;&nbsp;
                                                        </Link>
                                                    </Box>
                                                    :null
                                                }
                                            </div>
                                        </div>


                                        {/* <div className="card-header mb-4 mb-md-0 border-after position-relative"> */}
                                            {/* <div className="title about__title">
                                                <h2 className="pt-3 pt-md-0">{lang.about}</h2>
                                                <p className="mb-0 mb-md-3">{client.data?.about}</p>
                                            </div> */}

                                            <Box className=''>
                                                <Controller   
                                                    // placeholder={'Write about yourself'}
                                                    control={control}
                                                    name="about"
                                                    defaultValue={client.data?.about}
                                                    render={({ field: { onChange, value }}) => (
                                                        <Form.Control {...register('about')} readOnly as="textarea" className={`w-100 border rounded mb-4 p-2 textarea-div ${langauge==="heb"?"margin-left":"margin-right"}`}
                                                            value={bio}
                                                            style={{resize:"none", minHeight:1, unicodeBidi:"plaintext"}}  type="text" 
                                                            />
                                                    )}
                                                />
                                            </Box>
                                            {/* <Box className='d-block d-md-none'>
                                                <p>{client.data?.about}</p>
                                            </Box> */}
                                        {/* </div> */}



                                        
                                        <Row>
                                            <Col>
                                                <ul className="mb-0 list-unstyled">
                                                {!!(client.data?.gender) || !!(client.data?.relationship_status) ?
                                                    <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={abstract} height="25px" alt="Relationship" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap'>
                                                                {!! (client.data?.gender) ? <p className='mb-0 text-capitalize'>{client?.data?.gender === 'male' ? lang.male : lang.female}</p> : ''}
                                                                {!! (client.data?.relationship_status) ? <p className="mb-0 text-capitalize">{lang.relations[client?.data?.relationship_status]}</p> : ''}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : ''}
                                                      {!!(client.data?.gender) || !!(client.data?.purpose_relationship) ? 
                                                    <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={eye} height="21px" alt="Eye" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap'>
                                                            {!! (client.data?.gender) ? <p className='mb-0'>{client.data?.gender==="male"? lang.looking_for_new+' '+ lang.women: lang.looking_for_new+' '+lang.men}</p> : '' }
                                                            {!! (client.data?.purpose_relationship) ?    <p className="mb-0 text-capitalize">{lang.purposes[client.data?.purpose_relationship]}</p> : '' }
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : ''}
                                                    { !!(client.data?.eye_color) || !!(client.data?.hair_color) ? 
                                                     <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={man} height="25px" alt="Hair color" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap'>
                                                                {!! (client.data?.eye_color) ? <p className="mb-0 text-capitalize">{lang.eyeColors[client.data?.eye_color]}</p> : ''}
                                                                {!! (client.data?.hair_color) ? <p className="mb-0 text-capitalize">{lang.hairColors[client.data?.hair_color]}</p> : ''}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : ''}
                                                    {(client.data?.height) > 0 || !!(client.data?.body_type ) ? 
                                                     <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={body} height="25px" alt="img src" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap'>
                                                                {!! (client.data?.height) ?  <p className='mb-0'>{client.data?.height} {lang.cm}</p> : ''}
                                                                {!! (client.data?.body_type) ? <p className="mb-0 text-capitalize">{lang.bodies[client.data?.body_type]}</p> : ''}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : ''}

                                                    {!!(client.data?.language)?
                                                     <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={globe} height="20px" alt="img src" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap text-capitalize'>
                                                                {!! (client.data?.language) ? <p className="mb-0 text-capitalize">{lang.languages+": "}{client.data?.language && JSON.parse(client.data?.language).map((item, index)=>{return(<span dangerouslySetInnerHTML={{__html:lang.speaks[item['value']] + (JSON.parse(client.data?.language).length - 1 === index ? '' :   ", ")}}></span>)})}</p> : '' }
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : '' }
                                                    
                                                    {!!(client.data?.education) || !!(client.data?.occupation)?
                                                     <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={education} height="20px" alt="img src" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap text-capitalize'>
                                                                {!! (client.data?.education) ?  <p className='mb-0'>{lang.qualifications[client.data?.education]}</p> : ''}
                                                                {!! (client.data?.occupation) ?  <p className='mb-0'>{lang.jobs[client.data?.occupation]}</p> : ''}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : '' }




                                                     {!!(client.data?.smoke) || !!(client.data?.alcohol) || !!(client.data?.diet) || !!(client.data?.hobbies) ? 
                                                    <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={cheers} height="25px" alt="img src" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap text-capitalize'>
                                                                {!! (client.data?.smoke) ? <p className='mb-0'>{lang.cigrates[client.data?.smoke]}</p> : '' }
                                                                {!! (client.data?.alcohol) ?<p className="mb-0 text-capitalize">{lang.drinks[client.data?.alcohol]}</p> : '' }
                                                                {!! (client.data?.diet) ?<p className="mb-0 text-capitalize">{lang.foods[client.data?.diet]}</p> : '' }
                                                                {!! (client.data?.diet) ?<p className="mb-0 text-capitalize">{lang.foods[client.data?.diet]}</p> : '' }
                                                                
                                                                {/* {!! (client.data?.hobbies) ? <p className="mb-0 text-capitalize">{lang.hobbiesList[client.data?.hobbies]}</p> : '' } */}
                                                                {!! (client.data?.hobbies) ? <p className="mb-0 text-capitalize">{client.data?.hobbies && JSON.parse(client.data?.hobbies).map((item, index)=>{return(<span dangerouslySetInnerHTML={{__html:lang.hobbiesList[item['value']] + (JSON.parse(client.data?.hobbies).length - 1 === index ? '' :   ", ")}}></span>)})}</p> : '' }
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : '' }





                                                    {!!(client.data?.children) || !!(client.data?.pets) ? 
                                                    <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={Homepage} height="21px" alt="img src" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap'>
                                                            {!! (client.data?.children) ? <p className='mb-0'>{lang.kids[client.data?.children]}</p> : '' }
                                                            {!! (client.data?.pets) ?    <p className="mb-0 text-capitalize">{lang.animals[client.data?.pets]}</p> : '' }
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : '' }

                                                     {!!(client.data?.religion) || !!(client.data?.sign) ? 
                                                    <li className='mb-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon-element'>
                                                                <img src={balance} height="21px" alt="img src" />
                                                            </div>
                                                            <div className='user-info-element d-flex flex-wrap'>
                                                            {!! (client.data?.religion) ? <p className='mb-0'>{lang.religiouses[client.data?.religion]}</p> : '' }
                                                            {!! (client.data?.sign) ?    <p className="mb-0 text-capitalize">{lang.stars[client.data?.sign]}</p> : '' }
                                                            </div>
                                                        </div>
                                                    </li>
                                                    : '' }
                                                </ul>
                                            </Col>
                                            {/* <Col md={6}>
                                                <table>
                                                     <tr className='d-umd-none'>
                                                        <td><b className="text-capitalize">{lang.username}</b></td>
                                                        <td>{client.data?.name}</td>
                                                    </tr> 
                                                    <tr>
                                                        <td><img src={abstract} height="25px" /><b>{lang.date_birth}</b></td>
                                                        <td>{client.data?.birth_date?getDate(client.data.birth_date):"--"}</td>
                                                    </tr>
                                                    <tr className='d-umd-none'>
                                                        <td><b className="text-capitalize">{lang.gender}</b></td>
                                                        <td className="text-capitalize">{client.data?.gender}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b className="text-capitalize">{lang.height}</b></td>
                                                        <td>{client.data?.height}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>{lang.rel_status}</b></td>
                                                        <td className="text-capitalize">{client.data?.relationship_status}</td>
                                                    </tr>
                                                     <tr className='d-umd-none'>
                                                        <td><b>{lang.looking_for}</b></td>
                                                        <td>{client.data?.looking_for}</td>
                                                    </tr> 
                                                    <tr>
                                                        <td><b>{lang.relationship_purpose}</b></td>
                                                        <td>{client.data?.purpose_relationship}</td>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <td><b>{lang.body_type}</b></td>
                                                        <td className="text-capitalize">{client.data?.body_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b className="text-capitalize">{lang.occupation}</b></td>
                                                        <td className="text-capitalize">{client.data?.occupation}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>{lang.smoke}</b></td>
                                                        <td className="text-capitalize">{client.data?.smoke}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>{lang.alcohol}</b></td>
                                                        <td className="text-capitalize">{client.data?.alcohol}</td>
                                                    </tr>
                                                </table>
                                            </Col> */}
                                            {/* <Col md={6}>
                                                <div className="card-header border-0 p-0 mt-md-2 mt-3 d-md-block d-none">
                                                    <div className="title border-0">
                                                        <h3 className="mb-0">{lang.location}</h3>
                                                    </div>
                                                </div>
                                                <table className="d-md-block d-none">
                                                    <tr>
                                                        <td className="border-0"><b>{lang.current_location}</b></td>
                                                        <td className="text-capitalize border-0">{client.data?.city}</td>
                                                    </tr>
                                                </table>
                                            </Col> */}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                    :
                    <Heading className="py-5 text-danger text-center">No user profile found</Heading>
                }
            </div>
            {modalView?
                <UpgradePremiumModel onModalCancel={setModalView} />
            :null}
        </main>
    )
}
export default Profile